const environments = {
  dev: 'dev',
  prod: 'prod'
}

const firebaseConfig = {
  [environments.dev]: {
    apiKey: 'AIzaSyDUGdPhzNVQ80GsmoB5r1iAe9TRtn6qiKk',
    authDomain: 'owqlo-master-dev.firebaseapp.com',
    databaseURL: 'https://owqlo-master-dev.firebaseio.com',
    projectId: 'owqlo-master-dev',
    storageBucket: 'owqlo-master-dev.appspot.com',
    messagingSenderId: '453505352745',
    appId: '1:453505352745:web:f78eac2fa902a419460838',
    measurementId: 'G-653G9WN4NY'
  },
  [environments.prod]: {
    apiKey: 'AIzaSyCk8wSWqPgPUOEGsjA9ze6brgojF4FvK0I',
    authDomain: 'owqlo-master-production-id.firebaseapp.com',
    databaseURL: 'https://owqlo-master-production-id.firebaseio.com',
    projectId: 'owqlo-master-production-id',
    storageBucket: 'owqlo-master-production-id.appspot.com',
    messagingSenderId: '737501983455',
    appId: '1:737501983455:web:358966b523fb8405252f6a',
    measurementId: 'G-8M8F6GCE4G'
  }
}

// const getCurrentEnvironment = () => {
//   const mode = import.meta.env.MODE
//   return Object.values(environments).includes(mode) ? mode : environments.dev
// }
// const env = getCurrentEnvironment()
// if (env !== import.meta.env.MODE) {
//   console.warn(
//     `[Firebase Config] Valor inesperado en MODE: "${import.meta.env.MODE}". Usando "${env}" por defecto.`
//   );
// }

const env = environments.prod

export default firebaseConfig[env]
