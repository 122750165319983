// Composables
import { createRouter, createWebHistory } from 'vue-router'
import { getAuth, signInAnonymously } from 'firebase/auth'
import { useProjectStore } from '@/store/projectStore.js'
import { useDynamicFormStore } from '@/store/dynamicFormStore.js'
import { useProductStore } from '@/store/productStore.js'
import { useVendorStore } from '@/store/vendorStore.js'
import { useProjectUsersStore } from '@/store/projectUsersStore'
import { useLocaleStore } from '@/store/localeStore.js'
import pinia from '@/store'

const dynamicFormStore = useDynamicFormStore(pinia)
const projectStore = useProjectStore(pinia)
const projectUsersStore = useProjectUsersStore(pinia)

const routes = [
  {
    path: '/',
    component: () => import('@/layouts/main/MainLayout.vue'),
    children: [
      {
        path: '/inscription/organization/:organizationId/project/:projectId',
        name: 'inscription',
        component: () => import('@/views/inscription/InscriptionView.vue'),
        props: true,
        children: [
          {
            path: 'step-1',
            name: 'inscription-step-1',
            component: () => import('@/views/inscription/components/form/steps/1ParentForm.vue'),
            meta: {
              stepId: () => (dynamicFormStore.dataUser.parentProfileFields ? 1 : null),
            },
            props: true,
            beforeEnter: async (to, from, next) => {
              const { organizationId, projectId } = to.params
              if (!projectUsersStore.parents.length)
                await projectUsersStore.bindParents({
                  organizationId,
                  projectId,
                })
              next()
            },
          },
          {
            path: 'step-2',
            name: 'inscription-step-2',
            component: () => import('@/views/inscription/components/form/steps/2PlayerForm.vue'),
            meta: {
              stepId: () => (dynamicFormStore.dataUser.parentProfileFields ? 2 : 1),
            },
            props: true,
          },
          {
            path: 'step-2-products',
            name: 'inscription-step-2-products',
            component: () => import('@/views/products/ProductsListView.vue'),
            meta: {
              stepId: () => (dynamicFormStore.dataUser.parentProfileFields ? 2 : 1),
            },
            props: true,
          },
          {
            path: 'step-3',
            name: 'inscription-step-3',
            component: () => import('@/views/inscription/components/form/steps/3Waiver.vue'),
            meta: {
              stepId: () => (dynamicFormStore.dataUser.parentProfileFields ? 3 : 2),
            },
            props: true,
            beforeEnter: async (to, from, next) => {
              const { organizationId, projectId } = to.params
              if (!projectStore.waiver.length) await projectStore.bindWaiver({ organizationId, projectId })
              if (!projectStore.staticContentWaiver.length) await projectStore.bindStaticContentWaiver()
              next()
            },
          },
          {
            path: 'step-4',
            name: 'inscription-step-4',
            component: () => import('@/views/inscription/components/form/steps/4PaymentForm.vue'),
            meta: {
              stepId: () =>
                dynamicFormStore.dataUser.parentProfileFields
                  ? projectStore.project.features.waiver
                    ? 4
                    : 3
                  : projectStore.project.features.waiver
                    ? 3
                    : 2,
            },
            props: true,
          },
        ],
        beforeEnter: async (to, from, next) => {
          const { organizationId, projectId } = to.params
          const { email } = to.query
          const auth = getAuth()
          await signInAnonymously(auth)

          const productStore = useProductStore()
          const vendorStore = useVendorStore()
          const localeStore = useLocaleStore()

          await projectStore.bindProject({ organizationId, projectId })
          await dynamicFormStore.bindDataUser({ organizationId, projectId })
          await productStore.bindProducts({ organizationId, projectId })
          await vendorStore.bindVendor({ organizationId, projectId })
          await projectStore.bindTerms({ organizationId, projectId })
          await projectUsersStore.bindPlayers({ organizationId, projectId })
          await localeStore.setLocale()

          const allowedEmails = projectStore?.project?.allowedEmails || []
          const deadline = projectStore.project?.deadline?.toDate()

          if (!projectStore?.project) {
            next({ name: '404' })
          } else if (deadline && deadline < new Date() && !allowedEmails.includes(email)) {
            next({ name: 'DeadLine' })
          } else if (!projectStore.project?.active || !projectStore.project?.published) {
            next({ name: 'Closed' })
          } else if (projectStore.project.stock !== null && projectStore.project.stock <= 0) {
            next({ name: 'SoldOut' })
          } else if (
            dynamicFormStore?.dataUser?.parentProfileFields &&
            !projectStore?.form?.parentForm?.firstName &&
            to.name !== 'inscription-step-1'
          ) {
            next({
              name: 'inscription-step-1',
              params: { organizationId, projectId },
            })
          } else if (!dynamicFormStore?.dataUser?.parentProfileFields && to.name !== 'inscription-step-2') {
            next({
              name: 'inscription-step-2',
              params: { organizationId, projectId },
            })
          } else {
            next()
          }
        },
      },
      {
        path: '/404',
        name: '404',
        component: () => import('@/views/StatusView.vue'),
        props: {
          type: 'notFound',
          title: '404.sorry',
          description: '404.message',
        },
      },
      {
        path: '/sold-out',
        name: 'SoldOut',
        component: () => import('@/views/StatusView.vue'),
        props: {
          type: 'soldOut',
          title: 'soldOut.title',
          description: 'soldOut.subTitle',
        },
      },
      {
        path: '/dead-line',
        name: 'DeadLine',
        component: () => import('@/views/StatusView.vue'),
        props: {
          type: 'deadLine',
          title: 'deadLine.title',
          description: 'deadLine.description',
        },
      },
      {
        path: '/closed',
        name: 'Closed',
        component: () => import('@/views/StatusView.vue'),
        props: {
          type: 'closed',
          title: 'closed.title',
          description: 'closed.description',
        },
      },
      { path: '/:pathMatch(.*)*', redirect: '404' },
    ],
  },
]

const router = createRouter({
  history: createWebHistory(import.meta.env.VITE_BASE_URL),
  routes,
})

export default router
