import { Timestamp, doc, getDoc, setDoc, query, collection, onSnapshot, updateDoc } from 'firebase/firestore'
import { defineStore } from 'pinia'
import pinia from '@/store'
import { callBackend, db } from '@/plugins/firebase'
import { useUserStore } from '@/store/userStore.js'
import { useVendorStore } from '@/store/vendorStore.js'
import { useDynamicFormStore } from '@/store/dynamicFormStore'
import { useProjectUsersStore } from '@/store/projectUsersStore'
import getAmountTextFormatCurrency from '@/shared/utils/getAmountTextFormatCurrency.js'

const vendorStore = useVendorStore(pinia)
const dynamicFormStore = useDynamicFormStore(pinia)

export const useProjectStore = defineStore('projectStore', {
  state: () => ({
    project: null,
    form: {},
    paymentMethod: null,
    waiver: [],
    staticContentWaiver: [],
    terms: [],
    selectedShippingRate: null,
  }),
  getters: {
    price: (state) => state.project?.pricing?.priceOptions[0]?.instant?.amount ?? null,
    priceOptionId: (state) => state.project?.pricing?.priceOptions[0]?.id,
    timezone: (state) => state.project?.timezone,
    formatPrice: (state) => (price) =>
      state.price ? `${getAmountTextFormatCurrency(price.toFixed(2), vendorStore.currency)}` : null,
    isProjectFree: (state) => !state.project?.features?.price,
    productInscriptions: (state) => state.form.players.filter((p) => !p.isExtra),
    shippingRateIds: (state) =>
      state.form.players
        .flatMap((player) => player.cart)
        .filter((item) => item.type === 'product')
        .reduce((map, product) => product.shippingRateIds, {}),
    shippingFee(state) {
      if (this.shippingRateIds?.length) {
        return state.selectedShippingRate?.price ?? 0
      }
      return 0
    },
  },
  actions: {
    setSelectedShippingRate(shippingRate) {
      this.selectedShippingRate = shippingRate
    },
    setForm({ form }) {
      this.form = form
    },
    setPaymentMethod({ paymentMethod }) {
      this.paymentMethod = paymentMethod
    },
    async bindProject({ organizationId, projectId }) {
      const docRef = doc(db, 'properties', organizationId, 'projects', projectId)
      const docSnap = await getDoc(docRef)
      if (docSnap.exists()) {
        this.project = docSnap.data()
      } else {
        console.log('projectStore.js -> bindProject: No such document!')
      }
    },
    async bindWaiver({ organizationId, projectId }) {
      const colRef = query(collection(db, 'properties', organizationId, 'projects', projectId, 'waiver'))
      onSnapshot(colRef, (snapshot) => {
        this.waiver = snapshot.docs.map((waiverDoc) => waiverDoc.data())
      })
    },
    async bindTerms({ organizationId, projectId }) {
      const colRef = query(collection(db, 'properties', organizationId, 'projects', projectId, 'terms'))
      onSnapshot(colRef, (snapshot) => {
        this.terms = snapshot.docs.map((termDoc) => termDoc.data())
      })
    },
    async bindStaticContentWaiver() {
      const colRef = query(collection(db, 'staticContent', 'projectWaiver', 'waiver'))
      onSnapshot(colRef, (snapshot) => {
        this.staticContentWaiver = snapshot.docs.map((staticContentWaiverDoc) => staticContentWaiverDoc.data())
      })
    },
    async createEnrollment({ organizationId, projectId, data }) {
      if (data.info?.form) {
        const storagePath = `users/${data.userId}/membership/subscriber-project-${projectId}/`
        data.info.form = await dynamicFormStore.parseForm({
          form: data.info.form,
          storagePath,
        })
      }
      const enrollmentsRef = doc(db, 'properties', organizationId, 'projects', projectId, 'enrollments', data.id)
      await setDoc(enrollmentsRef, { ...data })
    },
    async createOrder({ formData, organizationId, projectId }) {
      const userStore = useUserStore()
      const projectUsersStore = useProjectUsersStore()

      let parentUser = null
      let parentInfoForm = null

      // 0. Verify that the parent's information is requested on the form and parse dynamic form if exists
      if (dynamicFormStore?.dataUser?.parentProfileFields) {
        const { parentDynamicForm, ...parentData } = formData.parentForm
        parentInfoForm = parentDynamicForm?.length ? this.parseDynamicForm({ dynamicForm: parentDynamicForm }) : null

        // 0.1. Back Create parent user if no exixts. If exists update parent user.
        parentUser = parentData.email ? await userStore.readByEmail(parentData.email) : false

        if (!parentUser) {
          parentUser = { id: null, ...parentData }
          parentUser.id = await userStore.createWithBackend({
            data: parentData,
          })
        } else {
          await userStore.update({ id: parentUser.id, data: parentData })
        }

        // 0.2. Change data of dynamicForm in all players
        if (parentInfoForm) {
          await Promise.all(
            projectUsersStore.players
              .filter((rP) => parentUser.linkedUsers?.children.includes(rP.id))
              .map(async (p) => {
                await userStore.updateProjectMembershipParentFields({
                  id: p.id,
                  role: 'subscriber',
                  entityType: 'project',
                  entityId: projectId,
                  data: { form: { ...parentInfoForm } },
                })
              }),
          )
        }
      }

      // 1. Back Create player user.
      const paymentItems = await Promise.all(
        formData.playerForm.map(async (player) => {
          const { playerDynamicForm, cart, ...playerData } = player
          const playerUser = { id: null }
          const existPlayer = playerData.email ? await userStore.readByEmail(playerData.email) : false
          if (!existPlayer) {
            playerUser.id = await userStore.createWithBackend({
              data: playerData,
            })
          } else {
            playerUser.id = existPlayer.id
            await userStore.update({ id: existPlayer.id, data: playerData })
          }

          if (!userStore.playerId) {
            userStore.setPlayerId({ playerId: playerUser.id })
          }

          // 1.1. Link users if the parent's data is requested
          if (dynamicFormStore?.dataUser?.parentProfileFields) {
            await callBackend('users/link', {
              user1: { id: parentUser?.id, relationshipRole: 'parent' },
              user2: { id: playerUser.id, relationshipRole: 'child' },
            })
          }

          // 1.2. Create enrollment
          const playerInfoForm = playerDynamicForm?.length
            ? this.parseDynamicForm({ dynamicForm: playerDynamicForm })
            : null
          // const {
          //   termsForm: { hasAcceptedMediaUse }
          // } = formData.parentForm || player
          const enrollment = {
            id: playerUser.id,
            userId: playerUser.id,
            createdAt: Timestamp.now(),
            updatedAt: Timestamp.now(),
            status: 'pendingApproval',
            role: 'player',
            // hasAcceptedMediaUse,
            enrolledBy: parentUser?.id ?? playerUser?.id,
            ...((parentInfoForm || playerInfoForm) && {
              info: {
                form: {
                  ...(parentInfoForm && { ...parentInfoForm }),
                  ...(playerInfoForm && { ...playerInfoForm }),
                },
              },
            }),
            ...(this.project.features?.waiver && {
              waiverSignatureBase64: formData.waiverForm.signature.replace('data:image/png;base64,', ''),
              waiverName: formData.waiverForm.name,
            }),
          }
          await this.createEnrollment({
            organizationId,
            projectId,
            data: enrollment,
          })
          if (this.isProjectFree) {
            await callBackend('projects/users/approve-enrollment', {
              organizationId,
              projectId,
              enrollmentId: enrollment.id,
              setPassword: true,
            })
          }

          // 1.3. Create payment items
          const cartItems = cart.map((item) => ({
            id: item.id,
            type: item.type,
            name: item.name,
            image: item.image,
            amount: item.amount,
            currency: vendorStore.currency,
            units: 1,
            organizationId,
            projectId,
            recipientUserId: playerUser.id,
            enrollmentId: enrollment.id,
            createdAt: Timestamp.now(),
            ...(item.type === 'project' && this.priceOptionId && { priceOptionId: this.priceOptionId }),
          }))
          return cartItems
        }),
      )
      if (this.isProjectFree) {
        return true
      }
      const paymentUserId = parentUser?.id ?? userStore.playerId
      // 2. Create default payment method
      const defaultPaymentMethodId = await userStore.createPaymentMethod({
        userId: paymentUserId,
        data: this.paymentMethod,
      })
      await userStore.update({
        id: paymentUserId,
        data: { defaultPaymentMethodId },
      })
      // 3. Add products extra in user cart
      const productsExtra = Object.values(
        formData.productsExtra.reduce((acc, p) => {
          if (!acc[p.id]) {
            acc[p.id] = {
              id: p.id,
              type: p.type,
              name: p.name,
              image: p.image,
              amount: 0,
              vendorProductId: p.vendorProductId ?? null,
              currency: vendorStore.currency,
              units: 0,
              organizationId,
              projectId,
              recipientUserId: paymentUserId,
              ...(this.selectedShippingRate && {
                shippingRateId: this.selectedShippingRate?.id,
              }),
            }
          }
          acc[p.id].units += 1
          acc[p.id].amount += p.amount
          return acc
        }, {}),
      )
      const allItems = [...paymentItems.flatMap((items) => items), ...productsExtra]
      // 4. Create address for shipping and includes in user cart
      let shippingAddressId = null
      if (formData.shippingAddressForm) {
        shippingAddressId = await userStore.createAddress({
          userId: paymentUserId,
          data: formData.shippingAddressForm,
        })
      }
      await userStore.createUserCart({
        userId: paymentUserId,
        paymentItems: allItems,
        addressId: shippingAddressId,
      })
      // 5. Create order
      const order = await callBackend('stripe/create-order', {
        userId: paymentUserId,
      })
      return order
    },
    parseDynamicForm({ dynamicForm }) {
      return dynamicForm.reduce((result, item) => {
        result[item.id] = item.value
        return result
      }, {})
    },
    async revokeAccess({ organizationId, projectId, email }) {
      try {
        // Validaciones
        if (!organizationId || !projectId || !email) {
          console.error('OrganizationId, projectId and email are required')
          throw new Error('Missing required parameters')
        }
        if (!this.project?.allowedEmails) {
          console.error('Project or allowedEmails not found')
          throw new Error('Project or allowedEmails not found')
        }

        if (!this.project?.allowedEmails?.includes(email)) {
          console.error('Email not found in allowed list')
          throw new Error('Email not found in allowed list')
        }

        const allowedEmails = this.project.allowedEmails.filter((e) => e !== email)
        const projectRef = doc(db, 'properties', organizationId, 'projects', projectId)

        // Actualizar allowedEmails
        await updateDoc(projectRef, { allowedEmails })

        // Crear log en subcolección
        const logRef = doc(collection(db, 'properties', organizationId, 'projects', projectId, 'logs'))
        await setDoc(logRef, {
          type: 'access_revoke',
          organizationId,
          projectId,
          email,
          createdAt: Timestamp.now(),
          previousAllowedEmails: this.project.allowedEmails,
          currentAllowedEmails: allowedEmails,
        })

        return {
          success: true,
          message: `Access successfully revoked for ${email}`,
        }
      } catch (error) {
        console.error('Error revoking access:', error)
        throw new Error(`Failed to revoke access: ${error.message}`)
      }
    },
  },
})
